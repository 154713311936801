import { storage } from '../utils/localStorage';
import { CUSTOM_ENVIRONMENT_URL, ENVIRONMENT } from './constants';
import { Environment } from './types';

const currentEnv = storage.getString(ENVIRONMENT);
const customEnvUrl = storage.getString(CUSTOM_ENVIRONMENT_URL);
const isStaging = __DEV__ || currentEnv === Environment.Stg;

export const API_BASE_URL =
  customEnvUrl && isStaging
    ? customEnvUrl
    : isStaging
      ? 'https://staging-api.agavehealth.com'
      : // 'http://localhost:4000'
        'https://api.agavehealth.com';
export const AUTH0_CLIENT_ID = isStaging
  ? 'LDfgAEnyrsAcRaS3TlR3A2hkzDT16jNS'
  : 'IeDcahiB5Eop3YmomMqyjoBXYrOkqU9J';
export const WEB_AUTH0_CLIENT_ID = isStaging
  ? 'hUiiWl5osKKsPZTujxNQrAE94PjZpnUU'
  : 'NtdqSgGsOzPVZSUj071s4Lf2nzMJSXXN';
export const AUTH0_DOMAIN = isStaging ? 'auth.agavehealth.com' : 'login.agavehealth.com';
export const AUTH0_AUDIENCE = isStaging
  ? 'https://gothrive.us.auth0.com/api/v2/'
  : 'https://agavehealth.us.auth0.com/api/v2/';
export const STREAM_IO_API_KEY = isStaging ? 'wj54zhx6ghb2' : 'p7u7beerwt5g';
export const STREAM_IO_APP_ID = isStaging ? '1213043' : '1226511';
export const IOS_REVCAT_API_KEY = 'appl_spRhNvXpMQoujLNgwKyCXlRUOfT';
export const AUTH0_SCOPE = 'openid profile email read:current_user offline_access';
